// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/routes/login.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/routes/login.tsx");
  import.meta.hot.lastModified = "1731718747801.5444";
}
// REMIX HMR END

import { json } from "@remix-run/node";
import { Form, Link, useActionData, useLoaderData, useNavigate, useNavigation, useSearchParams, useSubmit } from "@remix-run/react";
import { useState } from "react";
import PasswordInput from "~/components/common/password";
import AuthLayout from "~/components/layouts/auth";
import { authenticationService } from "~/services/auth.service";
import { createUserSession } from "~/session.server";
import { handleErrorToast, handleSuccessToast } from "~/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { getPlans } from "~/models/plan.server";
import axios from "axios";
import toast from "react-hot-toast";
import { loadStripe } from "@stripe/stripe-js";
export const loader = async ({
  request
}) => {
  const {
    plans
  } = await getPlans();
  return json({
    plans,
    stripePublicKey: process.env.STRIPE_PUBLISH_KEY,
    baseUrl: process.env.BASE_URL
  });
};
export const action = async ({
  request
}) => {
  const url = new URL(request.url);
  const redirectTo = url.searchParams.get("redirectTo") || "/dashboard";
  const result = await authenticationService.loginUser(request);
  if (result.success) {
    return createUserSession({
      redirectTo: `${redirectTo}`,
      remember: false,
      request,
      userId: result.data.userId
    });
  } else {
    return json({
      errors: result.errors
    }, {
      status: 401
    });
  }
};
export const meta = () => [{
  title: "Login"
}];
export default function LoginPage() {
  _s();
  const {
    plans,
    stripePublicKey,
    baseUrl
  } = useLoaderData();
  const actionData = useActionData();
  const [email, setEmail] = useState("");
  const transition = useNavigation();
  const isSubmitting = transition.state === "submitting";
  const navigate = useNavigate();
  const submit = useSubmit();
  const [searchParams] = useSearchParams();
  const redirectTo = searchParams.get("redirectTo");

  // const signInWithGoogle = async () => {
  //   const provider = new GoogleAuthProvider();
  //   try {
  //     const result = await signInWithPopup(auth, provider);
  //     const user = result.user;
  //     const formData = new FormData();
  //     formData.append("provider", "google");
  //     user.email && formData.append("email", user.email);
  //     user.displayName && formData.append("name", user.displayName);
  //     submit(formData, { method: "post" });
  //   } catch (error) {
  //     console.error("Error during sign-in:", error);
  //   }
  // };

  const resendVerification = async planId => {
    const response = await fetch(`/api/resend-verification?email=${email}&planId=${planId}`);
    const data = await response.json();
    if (data.success) {
      handleSuccessToast(data.msg);
      // navigate(`/email-verification?email=${email}`);
    } else {
      handleErrorToast(data.msg);
    }
  };
  const handlePayment = async plan => {
    const formData = new FormData();
    formData.append("planId", plan.id);
    formData.append("amount", `${plan.pricing?.amount}` || "0");
    formData.append("currency", plan.pricing?.currency || "gbp");
    formData.append("email", email);
    formData.append("successUrl", `${baseUrl}/auth/payment/success`);
    formData.append("cancelUrl", `${baseUrl}/auth/payment/cancel`);
    formData.append("email", email);
    try {
      const response = await axios.post(`${baseUrl}/api/checkout`, formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      });
      const {
        id
      } = response.data;
      const stripe = await loadStripe(stripePublicKey);
      await stripe?.redirectToCheckout({
        sessionId: id
      });
    } catch (error) {
      // Check if it's a response error and display the error message in a toast
      if (error.response && error.response.data && error.response.data.error) {
        toast.error(error.response.data.error);
      } else {
        toast.error("An unexpected error occurred.");
      }
    }
  };
  const subscribeNow = async () => {
    if (actionData?.errors?.data?.planId) {
      const plan = plans.find(plan => plan.id === actionData.errors.data.planId);
      if (plan) {
        handlePayment(plan);
      }
    }
  };
  return <AuthLayout>
      {redirectTo && <div className="notice d-flex bg-light-warning rounded border-warning border border-dashed p-6 mb-6">
          <FontAwesomeIcon className=" fs-2tx text-warning me-4" icon={faInfoCircle} />
          <div className="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">
            <div className="mb-3 mb-md-0 fw-semibold">
              <h4 className="text-gray-900 fw-bold">Login Notice!!!</h4>
              <div className="fs-6 text-gray-700 pe-7">
                {`You must login to continue to the ${redirectTo} page.`}
              </div>
            </div>
          </div>
        </div>}

      <Form className="form w-100" method="post" id="kt_sign_in_form">
        <div className="text-center mb-11">
          <h1 className="text-gray-900 fw-bolder mb-3">Sign In</h1>
        </div>

        {/* <div className="row g-3 mb-9">
          <div className="col-md-12">
            <button
              type="button"
              onClick={signInWithGoogle}
              className="btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100"
            >
              <img
                alt="Logo"
                src="assets/media/svg/brand-logos/google-icon.svg"
                className="h-15px me-3"
              />
              Sign in with Google
            </button>
          </div>
         </div>
         <div className="separator separator-content my-14">
          <span className="w-125px text-gray-500 fw-semibold fs-7">
            Or with email
          </span>
         </div> */}

        <div className="fv-row mb-8">
          <input type="email" placeholder="Email" name="email" autoComplete="new-email" className={`form-control bg-transparent ${actionData?.errors?.email ? "is-invalid" : ""}`} autoFocus={true} defaultValue="" onChange={event => setEmail(event.target.value)} />

          {actionData?.errors?.email && <div className="text-danger" id="email-error">
              {actionData.errors.email}

              {/* Verify Now Button for email verification */}
              {actionData.errors.email === "Your email is not verified" && <button onClick={() => resendVerification(actionData.errors.data?.planId)} type="button" className="btn btn-link" style={{
            color: "#1b84ff"
          }}>
                  Verify Now
                </button>}

              {/* Subscribe Now Button for missing or inactive subscription */}
              {actionData.errors.email === "You do not have an active subscription" && <button onClick={subscribeNow} // Function to initiate subscription
          type="button" className="btn btn-link" style={{
            color: "#1b84ff"
          }}>
                  Subscribe Now
                </button>}
            </div>}
        </div>

        <div className="fv-row mb-3">
          <PasswordInput error={actionData?.errors.password} value="" />
        </div>

        <div className="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8">
          <div></div>
          <Link to="/forgot-password" className="link-primary">
            Forgot Password ?
          </Link>
        </div>

        <div className="d-grid mb-10">
          <button disabled={isSubmitting} type="submit" id="kt_sign_in_submit" className="btn btn-primary">
            <span className="indicator-label">
              Sign In{" "}
              {isSubmitting && <span className="spinner-border spinner-border-sm align-middle ms-2"></span>}
            </span>

            {/* <span className="indicator-progress">
             Please wait...
             <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
             </span> */}
          </button>
        </div>

        <div className="text-gray-500 text-center fw-semibold fs-6">
          Not a Member yet?
          {/* <Link to="/account-type" className="link-primary">
            Sign up
           </Link> */}
          <Link to="/join?type=business" className="link-primary">
            Sign up
          </Link>
        </div>
      </Form>
    </AuthLayout>;
}
_s(LoginPage, "G2sT/zKJJ160y6huEuI4/alC38o=", false, function () {
  return [useLoaderData, useActionData, useNavigation, useNavigate, useSubmit, useSearchParams];
});
_c = LoginPage;
var _c;
$RefreshReg$(_c, "LoginPage");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;